<template>
  <div class="about-us">
    <PageHeader :navData="navData"></PageHeader>
    <!-- 内容区域 -->
    <div class="content">
      <div class="center">
        <div class="left animate__animated animate__fadeInLeft">
          <span>{{aboutUs.aboutTitle}}</span>
          <div class="text-box" v-html="aboutUs.aboutUs">
            <!-- {{}} -->
            <!-- <p>
              广州国巡机器人科技有限公司隶属于杭州国巡机器人科技有限公司，依托中科院先进技术资源，具备博士研发团队，研发人员占比70%以上。核心团队多年在智慧工业领域积累了丰富的机器视觉，Al算法，机械设计等行业领先的专利技术及经验。专业为电力、煤炭、路桥等机器人应用场景，提供安全智能化、管理智能化的机器人软硬件一站式服务
            </p>
            <p>
              广州国巡机器人科技有限公司是一家以机器人研发与制造为核心的创新型企业。公司依托中科院先进技术资源，由道生资本、中科优势资本等投资的机器人智造企业，具国资背景。承担多项科技部国家重点研发计划与重点专项，在机器人定位导航、运动控制、人机交互、机电一体化设计、机械臂控制、电机及驱动、云平台、计算机视觉等多个技术领域拥有核心专利。
            </p>    -->
          </div>
        </div>
        <div class="right animate__animated animate__fadeInRight">
          <!-- <img :src="../../assets/zsimg/gx.jpg"> -->
          <img :src="aboutImg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'aboutUs',
  data(){
    return {
      aboutUs:'',
      aboutImg:'',
      // 导航数据
      navData:{
        navigationDescribe:'',
        navigationImg:'',
        navigationName:''
      },
    }
  },
  created(){
    this.getNavImg()
    this.getData()
  },
  methods:{
    // 获取导航信息
    getNavImg(){      
      this.$axios.post(
  	    '/officialweb/data/getGwNavigation',{
          navigationType:'6'
        }
  	  ).then(res=>{      
        this.navData.navigationDescribe=res.data.navigationDescribe
        this.navData.navigationImg=res.data.navigationImg
        this.navData.navigationName=res.data.navigationName
  	  }).catch(()=>{
      })
    },
     // 获取首页的联系方式
    getData(){
      this.$axios.post(
  	    '/officialweb/data/getGwAboutUsBy'
  	  ).then(res=>{
        this.aboutUs=res.data
        this.aboutImg=res.data.aboutImgs.replace('[',"").replace("]","")
  	  }).catch(()=>{
      })
    },
  }
}
</script>
<style lang="less" scoped>



  .el-carousel__item h3 {
    color: #475669;
    font-size: .875rem;
    opacity: 0.75;
    line-height: 12.5rem;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

@mainColor:#E6031A;
@blueColor:#004EA2;
@mainSize:1400px;

.about-us{
  width: 100%;
  min-width: @mainSize;
  overflow: hidden;
}
.content{
  width: @mainSize;
  margin:0 auto;
  margin-top: 50px;
  .center{
    margin-top: 2.5rem;
    margin-bottom: 6.25rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    .left{
      width: 60%;
      overflow: hidden;
      font-size: 16px;
      color: #333;
      line-height: 2;
      >span{
        display: inline-block;   
        font-size: 28px;
        color: #333;
        padding-bottom: 21px;
        font-weight: 400;
        position: relative;
        &::after{
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 30px;
          height: 3.5px;
          background: @mainColor;
        }
      }
      /deep/ p{  
        text-indent: 2em;      
      }
      /deep/ p:nth-child(2){
        text-indent:0;
      }
    }
    .right{      
      width: 50%;
      height: 720px;
      margin-left: 95px;
      margin-top: 77px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .text-box{
      /deep/ p{
        padding: 0 !important;
        margin: 4px 0 !important;        
      }
      p{
        font-size: .875rem;
        color: #343434;
        line-height: 1.875rem;
      }
    }
  }
}

</style>
